@import url(https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap);
html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently */
  -webkit-overflow-scrolling: touch;
}

html,
body {
  height: 100%;
  width: 100%;
  overflow: none;
  -ms-scroll-chaining: none;
      overscroll-behavior: none;
}

body {
  margin: 0;
  font-family: 'Fredoka One', cursive;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.container {
  padding: 2rem 1rem;
  display: flex;
  flex-direction: column;
  position: relative;
}

h1 {
  text-align: center;
  margin: 0;
  font-size: 2.5rem;
}

p {
  color: #777;
  text-align: center;
  margin: 1rem 0 2rem 0;
  font-size: 1.2rem;
}

.nibblesImage {
  width: 100%;
  display: block;
  padding-right: 1rem;
  margin: 0 auto 1rem auto;
  animation: 1s ease-out 0s 1 slideInFromLeft;
  max-width: 400px;
}

.result {
  font-size: 4rem;
  text-align: center;
  color: #c18122;
  margin-top: 1.1rem;
}

.yes {
  /* color: white; */
  /* background: green; */
}

.no {
  /* color: white; */
  /* background: rgb(255, 81, 81); */
}

.button {
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  background: none;
}

.loadingContainer {
  margin: 1rem auto;

}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0);
  }
}
